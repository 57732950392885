<template>
  <div
    class="tw-flex tw-flex-col tw-gap-7 tw-border tw-px-4 tw-py-9"
    :style="`background-color: var(--c-${vars.backgroundColor}); border-radius: var(--rounded)`"
    style="border-radius: var(--rounded)"
  >
    <h3>{{ vars.titleText }}</h3>

    <div>
      <library-form v-if="form" :form="form" version="v2" :component="component"></library-form>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FormCard',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },

  created() {
    this.form = this.parseFormV2(this.vars.cardForm);
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
