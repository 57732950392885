<template>
  <div v-if="isVisible" ref="videoPopup" :key="componentKey" class="video-popup">
    <div class="popup">
      <div
        class="tw-mb-4 tw-flex tw-w-full tw-max-w-[90%] tw-cursor-pointer tw-items-center tw-justify-end tw-text-3xl tw-text-white lg:tw-max-w-[900px]"
        title="Close"
        @click="isVisibleModel = false"
      >
        &times;
      </div>
      <iframe
        v-if="!portrait"
        class="video"
        width="100%"
        height="460"
        :src="videoUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <template v-if="portrait">
        <div class="tw-relative">
          <div class="tw-aspect-[9/16] tw-h-full tw-w-full">
            <iframe
              class="tw-absolute tw-inset-0 tw-h-full tw-w-full"
              :src="videoUrl"
              style="border-radius: var(--rounded)"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { produceKey } from '~/server/core/helpers';

export default defineNuxtComponent({
  name: 'LibraryVideoPopup',

  props: {
    videoUrl: {
      required: true,
      type: String,
    },

    isVisible: {
      required: false,
      type: Boolean,
      default: false,
    },

    portrait: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      componentKey: produceKey(),
    };
  },

  computed: {
    isVisibleModel: {
      get() {
        return this.isVisible;
      },
      set(value: boolean) {
        this.$emit('update:isVisible', value);
        if (!value) {
          this.componentKey = produceKey();
        }
      },
    },
  },
});
</script>

<style scoped>
.popup {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-popup {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
}

.video-popup video,
.video-popup iframe {
  position: relative;
  max-width: 900px !important;
  outline: none;
}

.video-popup .close {
  top: 30px;
  right: 30px;
  max-width: 35px;
  cursor: pointer;
  filter: invert(1);
}

.video-popup {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 991px) {
  .video-popup video,
  .video-popup iframe {
    max-width: 90%;
  }
}
</style>
