<template>
  <div
    v-if="variants.v1 === variant"
    class="tw-relative tw-flex tw-w-full tw-flex-col tw-justify-center tw-gap-5 tw-px-4 tw-py-9 tw-text-start"
    :style="`background-color: var(--c-${vars.backgroundColor}); border-radius: var(--rounded);`"
  >
    <h4>{{ vars.titleText }}</h4>
    <p v-if="vars.descriptionText">
      {{ vars.descriptionText }}
    </p>
    <div class="tw-mt-2 tw-flex tw-flex-col tw-gap-4 lg:tw-flex-row lg:tw-gap-4">
      <nuxt-link
        v-for="(button, index) in buttons"
        :key="`card-btn-${index}`"
        :class="`n-${button.type} tw-w-fit`"
        :to="button.link"
        :external="true"
      >
        {{ button.label }}
      </nuxt-link>
    </div>
  </div>

  <div
    v-if="variants.v2 === variant"
    class="tw-relative tw-flex tw-w-full tw-flex-col tw-justify-center tw-gap-5 tw-px-4 tw-py-9 tw-text-start"
    :style="`background-color: var(--c-${vars.backgroundColor}); border-radius: var(--rounded);`"
  >
    <h4>{{ vars.titleText }}</h4>
    <p v-if="vars.descriptionText">
      {{ vars.descriptionText }}
    </p>
    <div class="tw-mt-2 tw-flex tw-flex-col tw-gap-4">
      <nuxt-link
        v-for="(button, index) in buttons"
        :key="`card-btn-${index}`"
        :class="`n-${button.type} tw-w-fit`"
        :to="button.link"
        :external="true"
      >
        {{ button.label }}
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'InfoCard',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    variant() {
      return useVariant(this.component);
    },

    buttons() {
      return this.groupedVariables.buttons;
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
