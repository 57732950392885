import { PFilterSearchType } from '~/units/properties/types';

export const onTheFlySearchBox = defineNuxtComponent({
  data() {
    return {
      searchInput: {
        keyword: '',
        mile: 0.25,

        is_available: false,
      },
      searchType: PFilterSearchType.SALES,

      locations: [],
    };
  },

  computed: {
    PFilterSearchType() {
      return PFilterSearchType;
    },
  },

  methods: {
    onSearchTypeClicked(type: PFilterSearchType) {
      this.searchType = type;
    },

    onSearched() {
      try {
        if (!this.locations.length && this.searchInput.keyword.trim()) {
          this.warningMessage('Please select a location');
          throw 'location-needed';
        }

        const url = (() => {
          if (this.searchType === PFilterSearchType.SALES) {
            return 'sales/property-for-sale';
          }
          return 'lettings/property-to-rent';
        })();

        const query: {
          locations?: any;
        } = {};
        if (this.locations.length > 0) {
          query.locations = JSON.stringify(this.locations);
        }

        this.redirectTo(
          {
            path: url,
            query,
          },
          true,
        );
      } catch (e) {
        console.error(e);
      }
    },
  },
});
