<template>
  <template v-if="sidebarComponents.length === 0">
    <section
      class="n-section-primary"
      v-if="variants.v1 === variant"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
    >
      <div class="tw-flex tw-w-full tw-max-w-lg tw-flex-col tw-items-start lg:tw-max-w-none lg:tw-items-center">
        <small v-if="vars.altTitleText">{{ vars.altTitleText }}</small>
        <h4 class="tw-mb-4 lg:tw-mb-10">{{ vars.titleText }}</h4>

        <!-- Slider for mobile -->
        <div class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-10 lg:tw-hidden">
          <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleLeft'" :duration="800">
            <Swiper
              :modules="[SwiperPagination]"
              :pagination="true"
              :slides-per-view="1"
              :space-between="20"
              effect="fade"
              :clickable="true"
              :watch-overflow="true"
              @slideChange="onSlideChange"
              :breakpoints="{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 24,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                },
              }"
              @swiper="onSwiper"
              class="tw-w-full"
            >
              <SwiperSlide
                v-for="(property, index) in properties"
                :key="`latestproperties-property-${index}`"
                @click="logImage(property)"
              >
                <theme6-properties-property-list-item
                  :property="property"
                  :is-match-information-hidden="true"
                  version="v1"
                  :pre-market-property-feature="true"
                />
              </SwiperSlide>
            </Swiper>
          </MotionGroup>
          <div>
            <LibrarySliderPagination
              :swiper="swiper"
              variant="v4"
              :can-paginate-back="canPaginateBack"
              :can-paginate-next="canPaginateNext"
            />
          </div>
        </div>

        <!-- Flex for desktop -->
        <div class="tw-hidden tw-w-full tw-justify-center tw-gap-4 lg:tw-flex lg:tw-flex-wrap">
          <div
            v-for="(property, index) in properties"
            :key="`latestproperties-property-${index}`"
            @click="logImage(property)"
            class="tw-basis-[32%]"
          >
            <theme6-properties-property-list-item
              :property="property"
              :is-match-information-hidden="true"
              version="v1"
              :pre-market-property-feature="true"
            />
          </div>
        </div>
        <nuxt-link
          :external="true"
          :class="`n-${vars.buttonTypeText} n-btn tw-mt-10 tw-self-start md:tw-self-center`"
          :to="vars.buttonLinkUrl"
          v-if="vars.buttonLinkUrl"
        >
          <div class="n-btn-container">
            <div class="n-btn-group">
              <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
              <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
            </div>
          </div>
        </nuxt-link>
      </div>
    </section>

    <section
      class="n-section-tertiary"
      v-if="variants.v2 === variant"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
    >
      <div class="tw-flex tw-w-full tw-max-w-lg tw-flex-col tw-items-center lg:tw-max-w-none">
        <h2 class="tw-mb-4 lg:tw-mb-10">{{ vars.titleText }}</h2>
        <div class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-6 tw-overflow-hidden">
          <!-- Adjust gap -->
          <!-- <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleLeft'" :duration="800"> -->
          <Swiper
            @slideChange="onSlideChange"
            :centered-slides="true"
            :center-insufficient-slides="false"
            :initial-slide="1"
            :space-between="40"
            effect="fade"
            :loop="true"
            :loopFillGroupWithBlank="true"
            :clickable="true"
            :breakpoints="{
              320: {
                slidesPerView: 1,
              },
            }"
            @swiper="onSwiper"
            class="tw-w-full lg:tw-w-[40%] lg:!tw-overflow-visible"
          >
            <SwiperSlide v-for="(property, index) in loopedProperties" :key="`latestproperties-property-${index}`">
              <theme6-properties-property-list-item
                :property="property"
                :is-match-information-hidden="true"
                version="v2"
                :pre-market-property-feature="true"
              />
            </SwiperSlide>
          </Swiper>

          <!-- </MotionGroup> -->
          <div>
            <LibrarySliderPagination
              :swiper="swiper"
              variant="v5"
              :can-paginate-back="canPaginateBack"
              :can-paginate-next="canPaginateNext"
            />
          </div>
        </div>
        <nuxt-link
          :external="true"
          :class="`n-${vars.buttonTypeText} n-btn tw-mt-10 tw-self-center`"
          :to="vars.buttonLinkUrl"
          v-if="vars.buttonLinkUrl"
        >
          <div class="n-btn-container">
            <div class="n-btn-group">
              <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
              <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
            </div>
          </div>
        </nuxt-link>
      </div>
    </section>
  </template>

  <template v-else-if="sidebarComponents.length > 0">
    <div
      class="tw-px-4 tw-py-9"
      v-if="variants.v1 === variant"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}; border-radius: var(--rounded);`"
    >
      <div class="tw-flex tw-w-full tw-max-w-lg tw-flex-col tw-items-start lg:tw-max-w-none">
        <small v-if="vars.altTitleText">{{ vars.altTitleText }}</small>
        <h4 class="tw-mb-4 lg:tw-mb-10">{{ vars.titleText }}</h4>

        <!-- Slider for mobile -->
        <div class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-10">
          <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleLeft'" :duration="800">
            <Swiper
              :modules="[SwiperPagination]"
              :pagination="false"
              :slides-per-view="1"
              :space-between="20"
              effect="fade"
              :clickable="true"
              :watch-overflow="true"
              @slideChange="onSlideChange"
              @swiper="onSwiper"
              class="tw-w-full"
            >
              <SwiperSlide
                v-for="(property, index) in properties"
                :key="`latestproperties-property-${index}`"
                @click="logImage(property)"
              >
                <theme6-properties-property-list-item
                  :property="property"
                  :is-match-information-hidden="true"
                  version="v1"
                  :pre-market-property-feature="true"
                />
              </SwiperSlide>
            </Swiper>
          </MotionGroup>
          <div>
            <LibrarySliderPagination
              :swiper="swiper"
              variant="v4"
              :can-paginate-back="canPaginateBack"
              :can-paginate-next="canPaginateNext"
            />
          </div>
        </div>

        <nuxt-link
          v-if="vars.buttonLinkUrl"
          :external="true"
          :class="`n-${vars.buttonTypeText} n-btn tw-mt-10 tw-self-start md:tw-self-center`"
          :to="vars.buttonLinkUrl"
        >
          <div class="n-btn-container">
            <div class="n-btn-group">
              <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
              <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
            </div>
          </div>
        </nuxt-link>
      </div>
    </div>

    <div
      v-if="variants.v2 === variant"
      class="sidebar tw-px-4 tw-py-9"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}; border-radius: var(--rounded);`"
    >
      <div class="tw-flex tw-w-full tw-max-w-lg tw-flex-col">
        <h4 class="tw-mb-4">{{ vars.titleText }}</h4>
        <div class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-6 tw-overflow-hidden">
          <!-- Adjust gap -->
          <Swiper
            @slideChange="onSlideChange"
            :centered-slides="true"
            :center-insufficient-slides="true"
            :initial-slide="1"
            :space-between="20"
            effect="fade"
            :loop="true"
            :clickable="true"
            @swiper="onSwiper"
            class="tw-w-full"
          >
            <SwiperSlide v-for="(property, index) in properties" :key="`latestproperties-property-${index}`">
              <theme6-properties-property-list-item
                version="v2"
                :property="property"
                :is-match-information-hidden="true"
                :pre-market-property-feature="true"
              />
            </SwiperSlide>
          </Swiper>
          <div>
            <LibrarySliderPagination
              :swiper="swiper"
              variant="v5"
              :can-paginate-back="canPaginateBack"
              :can-paginate-next="canPaginateNext"
            />
          </div>
        </div>
        <nuxt-link
          v-if="vars.buttonLinkUrl"
          :external="true"
          :class="`n-${vars.buttonTypeText} n-btn tw-mt-10 tw-self-center`"
          :to="vars.buttonLinkUrl"
        >
          <div class="n-btn-container">
            <div class="n-btn-group">
              <div class="n-btn-t1">{{ vars.buttonLabelText }}</div>
              <div class="n-btn-t2">{{ vars.buttonLabelText }}</div>
            </div>
          </div>
        </nuxt-link>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { PropertiesMixin } from '~/units/properties/mixins/properties.mixin';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'Theme6LatestProperties',

  mixins: [ComponentMixin, PropertiesMixin],

  async setup(props) {
    await fetchPropertiesManager(props.component, null);
  },

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      selectedProperty: null,
      isVisible: false,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  mounted() {
    this.onSlideChange();
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),
    loopedProperties() {
      return [...this.properties, ...this.properties];
    },
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
});
</script>

<style scoped>
::v-deep(.v3-left-arrow),
::v-deep(.v3-right-arrow) {
  margin-top: -100px;
}

::v-deep(.property-list-item) {
  min-height: unset !important;
}
.sidebar ::v-deep(.property-list-item) {
  background-color: var(--c-quaternary) !important;
}
</style>
