<template>
  <div class="n-blog-card shine-effect tw-group">
    <nuxt-link :to="`/blogs/${blog.url}`" :external="true" class="tw-inline-block !tw-p-0">
      <div class="tw-relative">
        <library-image :src="blog.image" :title="blog.title" :alt="blog.title" :w-ratio="493" :h-ratio="328" />
        <div
          class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-20"
          style="border-radius: var(--rounded)"
        ></div>
      </div>
      <h6>{{ blog.title }}</h6>
      <p>{{ blog.description }}</p>
    </nuxt-link>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme6BlogCard',

  mixins: [ComponentMixin],

  props: {
    blog: {
      required: true,
    },
    index: {
      required: true,
      type: Number,
    },
  },

  computed: {
    ROUTES() {
      return ROUTES;
    },
  },
});
</script>
